.content {
  &__title {
    flex-shrink: 0;
    padding: 0 0 0 var(--page-inset);
    margin-bottom: var(--spacing-24);

    @media (--small) {
      padding-left: var(--smart-page-inset);
    }
  }

  &__card {
    flex-shrink: 0;
    color: var(--color-dark);
    text-decoration: none;
    transition: all 250ms;

    &[href]:hover {
      padding-bottom: var(--spacing-4);
      margin-top: calc(0px - var(--spacing-4));
    }

    @media (--xlarge) {
      width: 24%;
    }

    & img {
      aspect-ratio: 3 / 4;
      object-fit: cover;
      border-radius: 4px;
      overflow: hidden;
    }

    & h3 {
      margin: var(--spacing-24) 0 var(--spacing-16);
    }
  }
}

div.slide {
  --slide-width: 70%;
  width: var(--slide-width);

  &:first-child {
    padding-left: var(--smart-page-inset);
    width: calc(var(--slide-width) + var(--smart-page-inset));
  }
  &:last-child:not(:first-child) {
    padding-right: var(--smart-page-inset);
    width: calc(var(--slide-width) + var(--smart-page-inset));
  }

  @media (--small) {
    --slide-width: calc(
      (100% - var(--smart-page-inset) * 2) / 3 - var(--column-gap) / 1.5
    );
  }
}
