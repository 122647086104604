.root {
  container-type: inline-size;
  container-name: root;

  & .wrapper {
    @container (min-width: 700px) {
      display: flex;
      gap: var(--spacing-16);

      & > div {
        width: 50%;
      }
    }
  }

  & button {
    appearance: none;
    background: none;
    border: 0;
    text-align: left;
    cursor: pointer;
    display: block;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-16);
    align-items: center;
    --svg-color: #000;

    @extend %typo-body1;

    & svg {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }
  }
}
.title {
  margin-bottom: var(--spacing-16);
}

.accordionitem {
  &__body {
    @extend %typo-body2;
    padding: var(--spacing-12) 0 0;
    padding-left: 0 !important;
  }
  &.open &__body {
    display: block;
  }
  &:first-child {
    border-top: 1px solid var(--color-divider);
  }
}
