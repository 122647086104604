.container__content {
  @media (--small) {
    display: flex;
  }

  & > * {
    @media (--small) {
      width: 50%;
    }
  }
}

.content__media {
  margin-bottom: var(--margin-spacing);
}

.content__title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--spacing-12);

  & h2,
  & h1 {
    margin-bottom: var(--spacing-12);

    @media (--small) {
      margin-bottom: var(--margin-spacing);
    }
  }

  & a {
    border-bottom: 1px solid var(--color-black);

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.hero {
  text-align: center;

  @media (--small) {
    text-align: left;
  }
}

.linkwrap {
  margin-top: var(--spacing-12);

  & a {
    border-bottom: 1px solid var(--color-black);

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.content__text {
  margin-top: var(--spacing-24);
  color: var(--color-text-secondary);
  @media (--small) {
    max-width: 83.33%;
    margin-top: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }

  a {
    display: inline-block;
  }

  p {
    line-height: 1.4375;
  }

  u {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: var(--color-primary-80);
    text-decoration-thickness: 1px;
  }

  & h3 {
    @extend %typo-subtitle2;
    text-transform: uppercase;
    margin-bottom: var(--spacing-8);
  }

  & p + p {
    margin-top: 1rem;
  }

  & p + h3 {
    margin-top: var(--spacing-24);
  }

  & p a {
    color: var(--color-primary);
  }
}

.unordered__list {
  list-style: square;
  padding-inline-start: 2rem;
  margin-block-start: 0.5rem;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;

  & > li {
    margin-top: var(--body-margin-small);
    padding-left: 1rem;
  }
}

.ordered__list {
  list-style: decimal;
  padding-inline-start: 2rem;
  margin-block-start: 0.5rem;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;

  & > li {
    margin-top: var(--body-margin-small);
    padding-left: 1rem;
  }
}
