.contentContainer {
  aspect-ratio: 4 / 2;

  & img {
    object-fit: cover;
    aspect-ratio: 3 / 2;
  }

  @media (--small) {
    & > div {
      position: relative;
      top: calc(var(--spacing-8) * -1);
    }
  }
}

.textWrapper {
  --product-card-radius: 4px;
  flex-direction: column;
  padding: var(--spacing-8) var(--spacing-16) var(--spacing-16)
    var(--spacing-16);
  display: flex;
  gap: var(--spacing-16);
  border-bottom-left-radius: var(--product-card-radius);
  border-bottom-right-radius: var(--product-card-radius);

  @media (--small) {
    --min-text-container-height: 134px;
    min-height: var(--min-text-container-height);
    flex-direction: row;
  }
}

.linkWrapper {
  width: 100%;
  gap: var(--spacing-16);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--small) {
    width: 50%;
  }
}

.content {
  width: 100%;
  @media (--small) {
    width: 50%;
  }
}

.title {
  @extend %typo-headline3;
}

.text {
  --line-break: 15px;
  margin-left: 0;
  margin-top: 0;
  padding-right: var(--spacing-8);
  @extend %typo-body2;

  &:not(:first-child) {
    margin-top: var(--line-break);
  }
}

.linkText {
  --spacing-9: 9px;
  display: flex;
  align-items: center;
  padding-block: var(--spacing-9);
  gap: var(--spacing-8);
  @extend %typo-caption1;

  & svg {
    width: 12px;
    height: 12px;
  }
}

.green {
  background: var(--color-primary);
  &.textWrapper {
    color: var(--color-white);
  }
  & svg {
    --svg-color: var(--color-white);
  }
}

.beige {
  background: var(--color-quaternary);
  &.textWrapper {
    color: var(--color-black);
  }
  & svg {
    --svg-color: var(--color-black);
  }
}
