.menu {
  display: flex;
  flex-direction: column;
  gap: var(--margin-spacing);
  justify-content: space-between;
  height: 100%;
  padding: 0 var(--page-inset);
}

.menu__list {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  list-style: none;
  padding: 0;
  margin: 0;
  gap: var(--margin-spacing);

  & a {
    @extend %typo-headline2;
    color: var(--color-white);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.secondary {
    flex: 0;

    & a {
      @extend %typo-body2;
    }
  }
}

.menuWrapper {
  height: 100%;
  min-height: calc(100svh - 200px);
  display: flex;
  align-items: center;
  transition: all 300ms;
  @media (--small) {
    min-height: 0;
  }
}

.mobile:has(.mobile__mobileModalContent) {
  & .desktopMenu__section {
    top: 0;
    padding-top: 0;
  }

  & .desktopMenu__header {
    padding-top: 0;
  }
}

.hasActive {
  transform: translateX(-100%);

  @media (--small) {
    transform: none;
  }
}

.mobile {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--page-inset);
}

.defaultTop {
  top: calc(var(--header-height) - 2px);
}

.extraTop {
  top: calc(var(--header-height) + (var(--snackbar-height)) - 2px);
}

.desktopMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: var(--spacing-32);

  & a {
    color: var(--color-primary);
    text-decoration: none;
  }

  &__section {
    width: 100%;
    padding-bottom: var(--spacing-40);
    position: fixed;
    left: 0;
    bottom: 0;

    @media (--mobile-only) {
      /* for IOS scroll */
      position: fixed;
      padding-top: calc(var(--header-height) - 2px);
      top: 0;
      height: auto;
      overflow: auto;
      max-height: unset;
      -webkit-overflow-scrolling: touch;
    }

    color: var(--color-primary-contrast);
    background: var(--color-primary);
    transform: translateX(200%);
    transition: all 300ms 0ms;

    @media (--small) {
      transform: none;
      clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    }

    &.active {
      transform: translateX(100%);
      @media (--small) {
        transform: none;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        transition: all 300ms 100ms;
      }
    }

    @media (--small) {
      padding-top: var(--spacing-24);
      bottom: unset;

      /* hover needed for desktop only */
      &.active,
      &:hover {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        transition: all 300ms 100ms;
      }
    }

    & a {
      color: var(--color-primary-contrast);
      text-decoration: none;
      padding: var(--spacing-12) 0;
      display: inline-block;

      @extend %typo-headline2;

      @media (--small) {
        @extend %typo-body2;
        padding: var(--spacing-4) 0;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    & ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    & .content__card {
      width: calc(100% / 2 - var(--page-inset));
      flex-shrink: 0;
      padding: 0;

      & > * {
        background: transparent;
      }

      @media (--small) {
        width: calc(100% / 3 - 5px);
      }

      & img {
        aspect-ratio: 3 / 4;
        object-fit: cover;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: var(--spacing-8);
      }

      & span {
        margin-top: var(--spacing-8);

        @extend %typo-body2;

        @media (--small) {
          @extend %typo-h3;
        }
      }

      &:first-child {
        margin-left: var(--page-inset);

        @media (--small) {
          margin: 0;
        }
      }

      &:last-child {
        margin-right: var(--page-inset);

        @media (--small) {
          margin: 0;
        }
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    gap: var(--spacing-40);

    margin-bottom: 0;

    @media (--small) {
      flex-direction: row;
      justify-content: initial;
      height: initial;
      gap: initial;
    }
  }

  &__column {
    width: 100%;
    padding-inline: var(--page-inset);

    @media (--small) {
      width: 25%;
      padding-inline: 0;
    }

    &:last-child {
      padding-bottom: var(--spacing-64);
      @media (--small) {
        padding-bottom: 0;
      }
    }
  }

  &__columnTitle {
    opacity: 0.5;
    margin-bottom: var(--spacing-8);
    display: block;
  }

  &__bloks {
    gap: 8px;
    display: flex;
    overflow-x: scroll;

    border-top: 1px solid var(--color-divider-contrast);
    border-bottom: 1px solid var(--color-divider-contrast);
    padding: var(--spacing-40) 0;

    @media (--small) {
      width: 50%;
      border: none;
      border-left: 1px solid var(--color-divider-contrast);
      padding: 0 var(--spacing-24);
      overflow-x: initial;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding-top: var(--spacing-16);

    --svg-color: var(--color-white);

    & svg {
      position: absolute;
      left: var(--page-inset);
    }

    & span {
      flex: 1;
      width: 100%;
      text-align: center;
    }
  }
}

.mobileMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;

  gap: var(--spacing-24);

  @extend %typo-headline2;

  --svg-color: var(--color-white);

  & li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & a {
    color: var(--color-white);
    text-decoration: none;
  }
}

.secondaryLinks {
  display: flex;
  flex-direction: column;
  @extend %typo-body1;

  & a {
    color: var(--color-white);
    padding: var(--spacing-8) 0;
  }
}

.divider {
  height: var(--spacing-16);
}
