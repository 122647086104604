.container {
  max-width: max(50%, 600px);
  margin-left: auto;
  /*
  & button {
    --button-padding-h: 16px;
    --button-padding-v: 12px;
    --button-fs: var(--button-size);
    --button-br: 4px;
    --button-gap: 8px;
    --svg-color: var(--color-white);

    font-family: var(--font-beausite);
    font-size: var(--font-size-s);
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0.01;
    display: inline-block;
    min-height: 48px;
    min-width: 80px;
    transition: all ease 150ms;
    cursor: pointer;
    align-items: center;
    font-size: var(--button-fs);
    background: var(--color-primary);
    color: var(--color-white);
    padding: calc(var(--button-padding-v) + 2px) var(--button-padding-h)
      calc(var(--button-padding-v) - 2px);
    border-radius: var(--button-br);
    appearance: none;
    border: 1px solid var(--color-primary);
    justify-content: center;
    text-decoration: none;
    line-height: 20px;
    text-align: center;

    @media (--small) {
      &:hover:not(:disabled) {
        background: var(--color-primary-dark);
        color: var(--color-white);
        --svg-color: var(--color-white);
      }
    }

    &:disabled {
      cursor: default;
      border: 1px solid var(--color-disabled-background);
    }

    & svg {
      width: 16px;
      height: 16px;
    }

    & path {
      transition: all ease 150ms;
    }
  }
    */
}

.left {
  margin-left: 0;
}

.center {
  margin-right: auto;
}
