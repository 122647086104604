.wrapper {
  position: relative;
  z-index: 2;
  text-align: center;
  padding-top: var(--margin-spacing);
  max-width: var(--page-inset-width);
  margin: 0 auto;

  & h2 {
    margin: calc(var(--base) * 1.5) 0 calc(var(--base) * 2);
  }

  & .text {
    padding-bottom: var(--spacing-16);
  }

  & p,
  & .richText {
    margin: 0 auto;
    max-width: 568px;
  }

  & .bottom {
    & .richText:has(span:first-child) {
      margin-top: var(--spacing-8-variable);
    }
  }

  & a {
    margin-top: 8px;
  }
}

.root {
  --hero-max-height: none;
  --hero-image-max-height: calc(100vh - var(--header-height) - 200px);
  max-height: var(--hero-max-height);
}

.wrapper__image {
  position: relative;
  max-height: var(--hero-image-max-height);

  &_overlay {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.01) 6.67%,
      rgba(0, 0, 0, 0.04) 13.33%,
      rgba(0, 0, 0, 0.08) 20%,
      rgba(0, 0, 0, 0.15) 26.67%,
      rgba(0, 0, 0, 0.23) 33.33%,
      rgba(0, 0, 0, 0.33) 40%,
      rgba(0, 0, 0, 0.44) 46.67%,
      rgba(0, 0, 0, 0.56) 53.33%,
      rgba(0, 0, 0, 0.67) 60%,
      rgba(0, 0, 0, 0.77) 66.67%,
      rgba(0, 0, 0, 0.85) 73.33%,
      rgba(0, 0, 0, 0.92) 80%,
      rgba(0, 0, 0, 0.96) 86.67%,
      rgba(0, 0, 0, 0.99) 93.33%,
      #000 100%
    ); /* gradient from design file */
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
  }

  & img,
  & video {
    max-height: var(--hero-image-max-height);
    object-fit: cover;
  }
}

.overlay {
  position: relative;
  --hero-max-height: calc(100vh - var(--header-height));
  --hero-image-max-height: var(--hero-max-height);

  & .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--color-light);
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    padding: 0;
    max-width: none;

    @media (--small) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

    & a {
      background: transparent;
      color: var(--color-light);
      border-color: transparent;
      --svg-color: var(--color-light);
    }
  }

  @media (--small) {
    & .topleft {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
    }
    & .topcenter {
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }
    & .topright {
      grid-column: 3 / span 1;
      grid-row: 1 / span 1;
    }
    & .middleleft {
      grid-column: 1 / 1;
      grid-row: 2 / span 1;
    }
    & .middlecenter {
      grid-column: 2 / span 1;
      grid-row: 2 / span 1;
    }
    & .middleright {
      grid-column: 3 / span 1;
      grid-row: 2 / span 1;
    }
    & .bottomleft {
      grid-column: 1 / span 1;
      grid-row: 3 / span 1;
    }
    & .bottomcenter {
      grid-column: 2 / span 1;
      grid-row: 3 / span 1;
    }
    & .bottomright {
      grid-column: 3 / span 1;
      grid-row: 3 / span 1;
    }
  }

  @media (--mobile-only) {
    & .top {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
    }
    & .middle {
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
    }

    & .bottom {
      grid-column: 1 / span 1;
      grid-row: 3 / span 1;
    }
  }
}

.no_image .wrapper {
  text-align: left;
  padding-top: var(--spacing-64);

  & p,
  & .richText {
    margin: 0;
    color: var(--color-text-secondary);
  }
}

.sideBySide {
  border-bottom: 1px solid var(--color-divider);

  @media (--small) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;

    --hero-max-height: calc(
      100vh - var(--header-height) - 100px
    ); /* show filters */
    --hero-image-max-height: var(--hero-max-height);

    & > * {
      width: 50%;
    }
  }

  & .wrapper {
    padding: var(--section-spacing) var(--page-inset);

    @media (--small) {
      text-align: left;
      padding-top: 0;
      padding: var(--section-spacing) var(--page-inset) var(--section-spacing) 0;
    }

    & h1,
    & h2 {
      margin: 0 0 var(--spacing-8);

      @media (--small) {
        text-align: left;
      }
    }

    & p,
    & .richText {
      text-align: center;
      opacity: 0.72;
      margin: 0;
      @extend %typo-body2;

      @media (--small) {
        text-align: left;
        max-width: 520px;
      }
    }
  }

  &[data-layout="full"] .wrapper {
    @media (--small) {
      padding-left: var(--smart-page-inset);
    }
  }
}
