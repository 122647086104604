.content__text {
  color: var(--color-text-secondary);

  & > * {
    margin-top: var(--body-margin);
  }

  & > *:first-child {
    margin-top: 0;
  }

  & p {
    margin: 1em 0;
  }

  & > a,
  & *:not([data-blok="true"]) > a {
    color: var(--color-text);
    text-decoration: underline;
    @extend %typo-body2;
  }

  & > [data-blok="true"] {
    margin: 1em 0;
  }

  & u {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: var(--color-primary-80);
    text-decoration-thickness: 1px;
  }

  & h2 {
    @extend %typo-subtitle1;
    margin-top: var(--section-spacing);
  }

  & h3 {
    @extend %typo-subtitle2;
    margin-top: var(--margin-spacing);
    text-transform: uppercase;
  }

  & > *:first-child {
    margin-top: 0;
  }

  @media (--small) {
    max-width: 83.33%;
  }
}

.content__textwrap {
  @media (--small) {
    max-width: max(50%, 600px);
    margin-left: auto;
  }
}

.left .content__textwrap {
  @media (--small) {
    margin-left: 0;
  }
}

.center .content__textwrap {
  @media (--small) {
    margin-inline: auto;
    text-align: center;

    & .content__text {
      @media (--small) {
        margin-inline: auto;
        padding: 0;
      }
    }
  }
}

.unordered__list {
  list-style: square;
  padding-inline-start: 2rem;
  margin-block-start: 0.5rem;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;

  & > li {
    margin-top: var(--body-margin-small);
    padding-left: 1rem;
  }
}

.ordered__list {
  list-style: decimal;
  padding-inline-start: 2rem;
  margin-block-start: 0.5rem;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;

  & > li {
    margin-top: var(--body-margin-small);
    padding-left: 1rem;
  }
}
