.wrapper {
  background-color: var(--color-tertiary);
  &[data-only-desktop="true"] .desktop {
    display: block !important;
  }

  & video {
    object-fit: cover;
    display: block;
  }
}

.desktop {
  display: none;
  @media (--large) {
    display: block;
  }
}

.mobile {
  display: block;
  @media (--large) {
    display: none;
  }
}
