.content {
  &__title {
    flex-shrink: 0;
    padding: 0 0 0 var(--page-inset);
    margin-bottom: var(--spacing-24);

    @media (--small) {
      margin-bottom: 0;
      padding: 0;
    }
  }

  &__card {
    flex-shrink: 0;
    text-decoration: none;
    transition: all 250ms;
    color: var(--color-text-secondary);

    &[href]:hover {
      padding-bottom: var(--spacing-4);
      margin-top: calc(0px - var(--spacing-4));
    }

    & img {
      aspect-ratio: 3 / 4;
      object-fit: cover;
      border-radius: 4px;
      overflow: hidden;
    }

    & h3 {
      margin: var(--spacing-24) 0 var(--spacing-16);
      color: var(--color-dark);
    }
  }
}

.swiper {
  padding-left: var(--smart-page-inset);
  padding-right: var(--smart-page-inset);
}

div.slide {
  --slide-width: 70%;
  width: var(--slide-width);

  @media (--small) {
    --slide-width: calc((100%) / 3 - var(--column-gap) / 2);
  }
}

.link {
  margin-block: var(--spacing-48);
}
