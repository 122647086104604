.base {
  &:not(.full) * {
    border-radius: 4px;
  }
}

@media (--large) {
  .left *,
  .right * {
    display: block;
    width: 50%;
  }

  .right * {
    margin-left: auto;
  }
}

.video {
  position: relative;
  aspect-ratio: 16 / 9;

  iframe {
    width: 100%;
  }
}

.desktop {
  display: none;

  @media (--small) {
    display: block;
  }
}

.mobile {
  display: block;

  @media (--small) {
    display: none;
  }
}

.full {
  width: 100%;
}
