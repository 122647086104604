.container {
  margin: var(--section-spacing) 0;

  & table,
  & tbody {
    width: 100%;

    @media (--mobile-only) {
      display: block;
    }
  }

  & th {
    text-align: left;
    color: var(--color-tertiary-text);
    padding: var(--spacing-16) var(--column-gap);
    @extend %typo-body2;

    @media (--mobile-only) {
      display: none;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  & tr {
    @media (--mobile-only) {
      display: block;
      border-bottom: 1px solid var(--color-divider);
      padding: var(--spacing-16) 0;
    }
  }

  & td {
    padding: var(--spacing-24) var(--column-gap);
    border-bottom: 1px solid var(--color-divider);
    @extend %typo-body2;
    vertical-align: top;

    @media (--mobile-only) {
      display: block;
      border: 0;
      padding: var(--spacing-8) 0;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  & a {
    color: var(--color-dark);
  }

  & tbody {
    & tr:first-child {
      @media (--mobile-only) {
        display: none;
      }
    }
  }
}

.head {
  display: flex;
  gap: var(--spacing-32);

  & > * {
    max-width: 400px;
    width: 100% !important;
  }
}

.retailersWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--spacing-64);

  @media (--small) {
    row-gap: var(--spacing-96);
    grid-template-columns: repeat(3, 1fr);
  }
}
