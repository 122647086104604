.root {
  max-width: 100vw;
  max-width: var(--page-width);
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: block;
  @media (--large) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .root.threecol & {
    grid-template-columns: repeat(3, 1fr);
  }

  .root.gap & {
    gap: var(--spacing-16);
  }
}

.item {
  width: 100%;
  @media (--xlarge) {
    width: auto;
  }

  & img,
  & video {
    aspect-ratio: 2 / 3;
  }
}
