.wrapper {
  text-decoration: none;
  color: var(--color-text-secondary);

  & img {
    border-radius: var(--spacing-4);
    aspect-ratio: 4 / 3;
    object-fit: cover;
  }
}

.name {
  margin: var(--spacing-24) 0 var(--spacing-16);
  color: var(--color-dark);
}

.excerpt {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @extend %typo-body2;
}

.title {
  padding-right: var(--spacing-12);
}
