.articles__grid {
  display: grid;
  margin-top: var(--section-spacing);
  grid-template-columns: 1fr;
  gap: var(--row-gap) var(--column-gap);

  @media (--small) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.filters {
  padding-top: var(--spacing-32);
}
