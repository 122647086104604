.wrapper {
  /* padding: 0px 20px 0px 20px; */
}

.helper {
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
  color: var(--color-text-secondary);
  padding-left: var(--page-inset);
  @extend %typo-body3;
}

.modalHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eff0ed;
  height: 72px;
  padding-left: var(--page-inset);
  padding-right: var(--page-inset);
}

.input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.input[type="search"]::-webkit-search-decoration,
.input[type="search"]::-webkit-search-cancel-button,
.input[type="search"]::-webkit-search-results-button,
.input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.input {
  @extend %typo-body1;
  display: flex;
  height: 100%;
  background: transparent;
  align-items: center;
  color: #3c3d3c;
  box-sizing: border-box;
  padding: var(--input-padding-v) var(--input-padding-h);
  border: 0;
  width: 100%;
  font-size: var(--input-font-size);
  transition: all var(--transition);
  line-height: var(--line-height);

  &:focus {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.02);
  }
  &:focus-visible {
    outline: 0;
  }

  &.active {
    padding: calc(var(--input-padding-v) + 4px) var(--input-padding-h)
      calc(var(--input-padding-v) - 4px);
  }

  &::placeholder {
    transition: all 0.1s ease;
    color: var(--color-text-secondary);
    opacity: 1;
    @extend %typo-body1;
  }

  &:hover {
    &::placeholder {
      opacity: 0.6;
      transform: translateY(-2px);
    }
  }
}

.productGrid {
  grid-template-rows: repeat(1, 1fr);
  @media (--small) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
}

.grid {
  padding-left: var(--page-inset);
  padding-right: var(--page-inset);
  padding-top: var(--spacing-48);

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--spacing-24);

  @media (--mobile-only) {
    padding-bottom: 72px;
  }

  @media (--small) {
    grid-template-columns: 2fr 12fr;
    grid-template-rows: 1fr;
  }
}

& .title {
  @extend %typo-headline4;
  font-size: 14px;
  color: var(--color-secondary-text);
  padding-bottom: var(--spacing-24);
}

.suggestions {
  display: none;
  @media (--small) {
    display: block;
  }
}

.popularSearches {
  & ul {
    margin-top: 0;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);
    cursor: pointer;
  }
}

.articleGrid {
  display: grid;
  grid-template-columns: repeat(1fr);
  gap: var(--row-gap) var(--column-gap);

  img {
    width: 100%;
    object-fit: cover;
  }

  @media (--small) {
    grid-template-columns: repeat(3, 1fr);
  }
}

& .title {
  @extend %typo-headline4;
  color: var(--color-secondary-text);
}

.results {
  padding-bottom: var(--spacing-32);
}

.emptySearchContainer {
}

.emptySearchResultPage {
  padding-bottom: var(--spacing-64);

  @media (--small) {
    padding-bottom: calc(var(--spacing-128) * 2);
  }
}

.hintContainer {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
  margin-top: var(--spacing-12);
  padding-left: var(--page-inset);
  p {
    @extend %typo-body2;
    color: var(--color-tertiary-text);
  }
}