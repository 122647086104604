.grid {
  display: grid;
  gap: var(--row-gap) var(--column-gap);
  grid-template-columns: repeat(2, 1fr);

  @media (--small) {
    grid-template-columns: repeat(4, 1fr);
  }

  & > div {
    width: 100%;
    height: fit-content;
  }
}
