.opening + p {
  margin-top: var(--spacing-24);
}

.wrapper {
  flex-direction: column;
  gap: var(--spacing-12);

  @media (--mobile-only) {
    display: flex;
  }

  & div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
  }

  & .title {
    @extend %typo-headline3;
  }

  & a {
    cursor: pointer;
    text-decoration: none;
    position: relative;
    color: var(--color-dark);
  }

  & a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: calc(-1 * var(--spacing-4));
    width: 100%;
    height: 1px;
    background-color: var(--color-dark);
  }

  & .hoursRow {
    --max-width: 300px;
    max-width: var(--max-width);
    width: 90%;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-8);
    align-items: center;
    justify-content: space-between;

    @media (--small) {
      --max-width: 400px;
    }
  }

  & .phone {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-8);
    align-items: center;
    p {
      color: var(--color-text-secondary);
    }
  }
}
